import ComposableBlocks from "../components/BlockContent/ComposableBlocks";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Head } from "../components/Head";
import { usePageTheme } from "../styles/pageThemes";
import { ThemeProvider } from "styled-components";
import { RouteHeading } from "../components/RouteHeading";
import { gridSquares } from "../styles/grid";
import styled from "styled-components";
import { ContentGroups } from "../constants/contentGroups";
import { GatsbyDeployMode, getDeployEnvironment } from "../../buildEnvironment";
import {
    EasyAgilePartnersHyperlinkBehavior,
    HyperlinkBehaviorContext,
} from "../components/Link/HyperlinkBehavior";
import { LandingPageBanner } from "../components/Banner/LandingPageBanner";

// mostly just a copy of LandingPage.tsx with a different query type
export default function PartnerPortalPage({
    data,
}: GatsbyTypes.PartnerPageQuery) {
    const { sanityPartnerPage } = data;
    const {
        strapline = {},
        body = [],
        seoDetails,
        funnelStage,
        pageTheme,
        pageBannerObject,
    } = sanityPartnerPage || {};

    const newTheme = usePageTheme(pageTheme);

    return (
        <IFrameContentOnly>
            <HyperlinkBehaviorContext.Provider
                value={EasyAgilePartnersHyperlinkBehavior}
            >
                <ThemeProvider theme={newTheme}>
                    <Head
                        contentGroup={ContentGroups[funnelStage]}
                        title={seoDetails?.title}
                        description={seoDetails?.description}
                        canonicalUrl={seoDetails?.canonicalUrl}
                        image={seoDetails?.openGraphImage?.asset?.url}
                        preventIndex={seoDetails?.preventIndex}
                        allowCookieBanner={false}
                    />

                    {strapline?.useBanner ? (
                        <LandingPageBanner
                            title={strapline.title}
                            subtitle={strapline.subtitle}
                            image={seoDetails?.openGraphImage?.asset?.url}
                            ctaText={pageBannerObject?.ctaText}
                            ctaLink={pageBannerObject?.ctaLink}
                            bannerImage={
                                pageBannerObject?.bannerImage?.asset?.url
                            }
                            bannerStyle={pageBannerObject?.bannerStyle}
                            sectionThemeName={pageBannerObject?.sectionTheme}
                        />
                    ) : (
                        <RouteHeading
                            title={strapline.title}
                            subtitle={strapline.subtitle}
                        />
                    )}

                    <ComposableBlocks blocks={body} />
                    <Spacer />
                </ThemeProvider>
            </HyperlinkBehaviorContext.Provider>
        </IFrameContentOnly>
    );
}
const IFrameContentOnly = (props) => {
    // you can preview anywhere except production
    const [canRender, setCanRender] = useState(
        getDeployEnvironment() !== GatsbyDeployMode.Production
    );

    useEffect(() => {
        // but if you're in production
        if (getDeployEnvironment() === GatsbyDeployMode.Production) {
            if (typeof window !== undefined) {
                // you can preview if you're iframed only
                // we let the X-Frame-Options in the header set the allowed iframe parents
                const isIFramed = window.parent !== window;
                setCanRender(isIFramed);
            }
        }
    }, []);

    if (canRender) {
        return <>{props.children}</>;
    }
    return null;
};

export const Spacer = styled.div`
    height: ${gridSquares(3.5)};
`;
export const pageQuery = graphql`
    query PartnerPortalPage($id: String!) {
        sanityPartnerPage(id: { eq: $id }) {
            body {
                ... on SanityBlockList {
                    ...SanityBlockListFields
                }
                ... on SanityBlockTestimonial {
                    ...SanityBlockTestimonialFields
                }
                ... on SanityBlockVideo {
                    ...SanityBlockVideoFields
                }
                ... on SanityRelatedArticles {
                    ...SanityRelatedArticlesFields
                }
                ... on SanityPageBlockContent {
                    ...SanityPageBlockContentFields
                }
                ... on SanityBlockImageWithText {
                    ...SanityBlockImageWithTextFields
                }
                ... on SanityBlockTwoColumn {
                    ...SanityBlockTwoColumnFields
                }
                ... on SanityCarouselWithSideText {
                    ...SanityCarouselWithSideTextFields
                }
                ... on SanityVerticalPadding {
                    ...SanityVerticalPadding
                }
                ... on SanityJobBoard {
                    ...SanityJobBoard
                }
                ... on SanityBigMarkerRegistration {
                    ...SanityBigMarkerRegistration
                }
            }
            funnelStage
            pageUrl {
                current
            }
            seoDetails {
                canonicalUrl
                description
                openGraphImage {
                    asset {
                        url
                    }
                }
                title
                preventIndex
            }
            strapline {
                product
                subtitle
                title
                useBanner
            }
            pageTheme
            pageBannerObject {
                sectionTheme
                ctaText
                ctaLink
                bannerStyle
                bannerImage {
                    asset {
                        url
                    }
                }
            }
        }
    }
`;
